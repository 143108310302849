export const COMMUNITY_IS_ADMIN_REQUEST = 'COMMUNITY_IS_ADMIN_REQUEST'
export const COMMUNITY_IS_ADMIN_SUCCESS = 'COMMUNITY_IS_ADMIN_SUCCESS'
export const COMMUNITY_IS_ADMIN_FAILURE = 'COMMUNITY_IS_ADMIN_FAILURE'
export const COMMUNITY_IS_ADMIN_API_ERROR_MESSAGES = {
  'ERROR': 'Failed to get admin request.',
  'NOT_ADMIN': 'NOT_ADMIN'
}

export const COMMUNITY_GET_POSTS_REQUEST = 'COMMUNITY_GET_POSTS_REQUEST'
export const COMMUNITY_GET_POSTS_SUCCESS = 'COMMUNITY_GET_POSTS_SUCCESS'
export const COMMUNITY_GET_POSTS_FAILURE = 'COMMUNITY_GET_POSTS_FAILURE'
export const COMMUNITY_GET_POSTS_API_ERROR_MESSAGES = {
  'ERROR': 'Failed to get posts.'
}