export const SHOP_GET_CUSTOMER_DETAILS_REQUEST = 'SHOP_GET_CUSTOMER_DETAILS_REQUEST'
export const SHOP_GET_CUSTOMER_DETAILS_SUCCESS = 'SHOP_GET_CUSTOMER_DETAILS_SUCCESS'
export const SHOP_GET_CUSTOMER_DETAILS_FAILURE = 'SHOP_GET_CUSTOMER_DETAILS_FAILURE'
export const SHOP_GET_CUSTOMER_DETAILS_API_ERROR_MESSAGES = {
  'ERROR': 'Failed to get user contacts.',
  'NOT_AUTHORISED': 'Please login.'
}

export const SHOP_SAVE_CUSTOMER_DETAILS_REQUEST = 'SHOP_SAVE_CUSTOMER_DETAILS_REQUEST'
export const SHOP_SAVE_CUSTOMER_DETAILS_SUCCESS = 'SHOP_SAVE_CUSTOMER_DETAILS_SUCCESS'
export const SHOP_SAVE_CUSTOMER_DETAILS_FAILURE = 'SHOP_SAVE_CUSTOMER_DETAILS_FAILURE'
export const SHOP_SAVE_CUSTOMER_DETAILS_API_ERROR_MESSAGES = {
  'ERROR': 'Failed to save user contacts.',
  'NOT_AUTHORISED': 'Please login.'
}

export const SHOP_GET_CUSTOMER_ORDERS_REQUEST = 'SHOP_GET_CUSTOMER_ORDERS_REQUEST'
export const SHOP_GET_CUSTOMER_ORDERS_SUCCESS = 'SHOP_GET_CUSTOMER_ORDERS_SUCCESS'
export const SHOP_GET_CUSTOMER_ORDERS_FAILURE = 'SHOP_GET_CUSTOMER_ORDERS_FAILURE'
export const SHOP_GET_CUSTOMER_ORDERS_API_ERROR_MESSAGES = {
  'ERROR': 'Failed to get user orders.'
}

export const SHOP_GET_COUNTRY_LIST_REQUEST = 'SHOP_GET_COUNTRY_LIST_REQUEST'
export const SHOP_GET_COUNTRY_LIST_SUCCESS = 'SHOP_GET_COUNTRY_LIST_SUCCESS'
export const SHOP_GET_COUNTRY_LIST_FAILURE= 'SHOP_GET_COUNTRY_LIST_FAILURE'
export const SHOP_GET_COUNTRY_LIST_API_ERROR_MESSAGES = {
  'ERROR': 'Failed to get country list.'
}

export const SHOP_GET_AMERICA_STATE_LIST_REQUEST = 'SHOP_GET_AMERICA_STATE_LIST_REQUEST'
export const SHOP_GET_AMERICA_STATE_LIST_SUCCESS = 'SHOP_GET_AMERICA_STATE_LIST_SUCCESS'
export const SHOP_GET_AMERICA_STATE_LIST_FAILURE= 'SHOP_GET_AMERICA_STATE_LIST_FAILURE'
export const SHOP_GET_AMERICA_STATE_LIST_API_ERROR_MESSAGES = {
  'ERROR': 'Failed to get American state list.'
}

export const SHOP_GET_GROUP_PRODUCTS_REQUEST = 'SHOP_GET_GROUP_PRODUCTS_REQUEST'
export const SHOP_GET_GROUP_PRODUCTS_SUCCESS = 'SHOP_GET_GROUP_PRODUCTS_SUCCESS'
export const SHOP_GET_GROUP_PRODUCTS_FAILURE = 'SHOP_GET_GROUP_PRODUCTS_FAILURE'
export const SHOP_GET_GROUP_PRODUCTS_API_ERROR_MESSAGES = {
  'ERROR': 'Failed to get shop products.',
  'NO_PRODUCTS_FOUND': 'There are no products available.'
}

export const SHOP_GET_PRODUCT_REQUEST = 'SHOP_GET_PRODUCT_REQUEST'
export const SHOP_GET_PRODUCT_SUCCESS = 'SHOP_GET_PRODUCT_SUCCESS'
export const SHOP_GET_PRODUCT_FAILURE = 'SHOP_GET_PRODUCT_FAILURE'
export const SHOP_GET_PRODUCT_API_ERROR_MESSAGES = {
  'ERROR': 'Failed to get shop product.',
  'NO_PRODUCT_FOUND': 'That product is not available.'
}

export const SHOP_GET_GROUPS_REQUEST = 'SHOP_GET_GROUPS_REQUEST'
export const SHOP_GET_GROUPS_SUCCESS = 'SHOP_GET_GROUPS_SUCCESS'
export const SHOP_GET_GROUPS_FAILURE = 'SHOP_GET_GROUPS_FAILURE'
export const SHOP_GET_GROUPS_API_ERROR_MESSAGES = {
  'ERROR': 'Failed to get shop groups.',
  'NO_GROUPS_FOUND': 'There are no groups available.'
}

export const SHOP_CREATE_SALES_TRANSACTION_REQUEST = 'SHOP_CREATE_SALES_TRANSACTION_REQUEST'
export const SHOP_CREATE_SALES_TRANSACTION_SUCCESS = 'SHOP_CREATE_SALES_TRANSACTION_SUCCESS'
export const SHOP_CREATE_SALES_TRANSACTION_FAILURE = 'SHOP_CREATE_SALES_TRANSACTION_FAILURE'
export const SHOP_CREATE_SALES_TRANSACTION_API_ERROR_MESSAGES = {
  'ERROR': 'Failed to create sales transaction.',
  'CUSTOMER_TAX_LOCATION_INVALID': 'Your address cannot be verified. Please re-enter correct addresses in the Contact Details screen.'
}

export const SHOP_UPDATE_SALES_TRANSACTION_STATUS_REQUEST  = 'SHOP_UPDATE_SALES_TRANSACTION_STATUS_REQUEST'

export const SHOP_RESET_API_ERRORS = 'SHOP_RESET_API_ERRORS'

export const SHOP_UPDATE_BASKET_REQUEST = 'SHOP_UPDATE_BASKET_REQUEST'
