export const PROTEUS_GET_MODULES_REQUEST = 'PROTEUS_GET_MODULES_REQUEST'
export const PROTEUS_GET_MODULES_SUCCESS = 'PROTEUS_GET_MODULES_SUCCESS'
export const PROTEUS_GET_MODULES_FAILURE = 'PROTEUS_GET_MODULES_FAILURE'
export const PROTEUS_MODULES_API_ERROR_MESSAGES = {
  'OOPS': 'Get modules failed.'
}
export const PROTEUS_GET_APP_SETTINGS_REQUEST = 'PROTEUS_GET_APP_SETTINGS_REQUEST'
export const PROTEUS_GET_APP_SETTINGS_SUCCESS = 'PROTEUS_GET_APP_SETTINGS_SUCCESS'
export const PROTEUS_GET_APP_SETTINGS_FAILURE = 'PROTEUS_GET_APP_SETTINGS_FAILURE'
export const PROTEUS_APP_SETTINGS_API_ERROR_MESSAGES = {
  'ERROR': 'There was an error.',
  'SETTINGS_NOT_FOUND': 'Settings not found.'
}
export const PROTEUS_GET_GALLERY_ITEMS_REQUEST = 'PROTEUS_GET_GALLERY_ITEMS_REQUEST'
export const PROTEUS_GET_GALLERY_ITEMS_SUCCESS = 'PROTEUS_GET_GALLERY_ITEMS_SUCCESS'
export const PROTEUS_GET_GALLERY_ITEMS_FAILURE = 'PROTEUS_GET_GALLERY_ITEMS_FAILURE'
export const PROTEUS_GALLERY_ITEMS_API_ERROR_MESSAGES = {
  'ERROR': 'There was an error.',
}
export const PROTEUS_APPEND_GALLERY_ITEM_REQUEST = 'PROTEUS_APPEND_GALLERY_ITEM_REQUEST'
export const PROTEUS_APPEND_GALLERY_ITEM_SUCCESS = 'PROTEUS_APPEND_GALLERY_ITEM_SUCCESS'
export const PROTEUS_CONNECT_CONTROLLER_REQUEST = 'PROTEUS_CONNECT_CONTROLLER_REQUEST'
export const PROTEUS_CONNECT_CONTROLLER_SUCCESS = 'PROTEUS_CONNECT_CONTROLLER_SUCCESS'
export const PROTEUS_CONNECT_CONTROLLER_FAILURE = 'PROTEUS_CONNECT_CONTROLLER_FAILURE'
export const PROTEUS_CONNECT_CONTROLLER_ERROR_MESSAGES = {
  'UNKOWN_ERROR': 'There was an error connecting your controller.',
  'CONNECT_CANCELLED': 'You cancelled your connection.',
}
export const PROTEUS_GET_CONTROLLER_CONFIG_REQUEST = 'PROTEUS_GET_CONTROLLER_CONFIG_REQUEST'
export const PROTEUS_GET_CONTROLLER_CONFIG_SUCCESS = 'PROTEUS_GET_CONTROLLER_CONFIG_SUCCESS'
export const PROTEUS_GET_CONTROLLER_CONFIG_FAILURE = 'PROTEUS_GET_CONTROLLER_CONFIG_FAILURE'
export const PROTEUS_GET_CONTROLLER_CONFIG_ERROR_MESSAGES = {
  'UNKOWN_ERROR': 'There was an error retrieving controller config.',
  'CONTROLLER_CONFIG_REQUEST_FAILED': 'Request controller config failed.',
}
export const PROTEUS_SET_UNITY_READY_REQUEST = 'PROTEUS_SET_UNITY_READY_REQUEST'
export const PROTEUS_SET_UNITY_READY_SUCCESS = 'PROTEUS_SET_UNITY_READY_SUCCESS'
export const PROTEUS_SET_GAMEPAD_READY_REQUEST = 'PROTEUS_SET_GAMEPAD_READY_REQUEST'
export const PROTEUS_SET_GAMEPAD_READY_SUCCESS = 'PROTEUS_SET_GAMEPAD_READY_SUCCESS'
export const PROTEUS_SET_MAPPING_MODE_REQUEST = 'PROTEUS_SET_MAPPING_MODE_REQUEST'
export const PROTEUS_SET_MAPPING_MODE_SUCCESS = 'PROTEUS_SET_MAPPING_MODE_SUCCESS'
