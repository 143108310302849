export const AUTH_GET_USER_REQUEST = 'AUTH_GET_USER_REQUEST'
export const AUTH_GET_USER_SUCCESS = 'AUTH_GET_USER_SUCCESS'
export const AUTH_GET_USER_FAILURE = 'AUTH_GET_USER_FAILURE'
export const AUTH_SAVE_USER_REQUEST = 'AUTH_SAVE_USER_REQUEST'
export const AUTH_SAVE_USER_SUCCESS = 'AUTH_SAVE_USER_SUCCESS'
export const AUTH_SAVE_USER_FAILURE = 'AUTH_SAVE_USER_FAILURE'
export const AUTH_SAVE_USER_CLEAR_API_RESPONSE = 'AUTH_SAVE_USER_CLEAR_API_RESPONSE'
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'
export const AUTH_SIGNUP_REQUEST = 'AUTH_SIGNUP_REQUEST'
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS'
export const AUTH_SIGNUP_FAILURE = 'AUTH_SIGNUP_FAILURE'
export const AUTH_RESEND_EMAIL_REQUEST = 'AUTH_RESEND_EMAIL_REQUEST'
export const AUTH_RESEND_EMAIL_SUCCESS = 'AUTH_RESEND_EMAIL_SUCCESS'
export const AUTH_RESEND_EMAIL_FAILURE = 'AUTH_RESEND_EMAIL_FAILURE'
export const AUTH_CONFIRM_EMAIL_REQUEST = 'AUTH_CONFIRM_EMAIL_REQUEST'
export const AUTH_CONFIRM_EMAIL_SUCCESS = 'AUTH_CONFIRM_EMAIL_SUCCESS'
export const AUTH_CONFIRM_EMAIL_FAILURE = 'AUTH_CONFIRM_EMAIL_FAILURE'
export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST'
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS'
export const AUTH_RESET_PASSWORD_FAILURE = 'AUTH_RESET_PASSWORD_FAILURE'
export const AUTH_PASSWORD_CHANGE_REQUEST = 'AUTH_PASSWORD_CHANGE_REQUEST'
export const AUTH_PASSWORD_CHANGE_SUCCESS = 'AUTH_PASSWORD_CHANGE_SUCCESS'
export const AUTH_PASSWORD_CHANGE_FAILURE = 'AUTH_PASSWORD_CHANGE_FAILURE'

export const AUTH_RESET_API_ERROR = 'AUTH_RESET_API_ERROR'

export const GET_USER_API_ERROR_MESSAGES = {
  'OOPS': 'Get user failed.'
}

export const SAVE_USER_API_ERROR_MESSAGES = {
  'OOPS': 'Saving failed.',
  'SAVE_COMPLETE': 'Save conplete.',
  'EMAIL_ALREADY_REGISTERED': 'That email is already registered.'
}

export const SIGNIN_API_ERROR_MESSAGES = {
  'OOPS': 'Sign In failed.',
  'ACCOUNT_NOT_FOUND': 'Sorry, sign in failed.',
  'NOT_AUTHORISED': 'Sorry, sign in failed.',
  'EMAIL_NOT_CONFIRMED': 'Please confirm your email address first.'
}

export const SIGNUP_API_ERROR_MESSAGES = {
  'SIGNUP_EMAIL_ALREADY_REGISTERED': 'That email is already registered.'
}

export const RESEND_EMAIL_API_ERROR_MESSAGES = {
  'OOPS': 'Email resend failed.'
}

export const CONFIRM_EMAIL_API_ERROR_MESSAGES = {
  'OOPS': 'Confirm email failed.'
}

export const RESET_PASSWORD_API_ERROR_MESSAGES = {
  'OOPS': 'Password reset failed.',
  'EMAIL_NOT_FOUND': 'Sorry, that email address is not registered.'
}

export const PASSWORD_CHANGE_API_ERROR_MESSAGES = {
  'OOPS': 'Password could not be changed.'
}