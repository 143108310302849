import React from 'react'

import './Community.css'

const CommunityContainer: React.FC = () => {

  return (
    <div className={'App-container'}>
      <div className='Community-container-top'>
        CommunityContainer
      </div>
    </div>
  )
}

export default CommunityContainer